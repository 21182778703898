<template>
  <Dialog
    v-model:visible="displayModal"
    style="width: 70%"
    :modal="true"
    :closeOnEscape="true"
    :dismissableMask="true"
    :breakpoints="{'1300px': '100vw'}" :style="{width: '90vw'}"
  >
    <template #header>
      <div style="color:#1c3faa;" class="flex justify-center items-center">
        <i class="pi pi-list mr-2 text-xl"></i>
        <h4>
          <strong style="font-size:20px">{{ previewEntrada ? 'Previsualizar entrada' : 'Detalles de la recepción'}}</strong>
        </h4>
      </div>
    </template>
    <Card class="w-full">
      <template #content>
        <div class="w-full text-xxs">
          <div class="grid grid-cols-1 xl:grid-cols-2 lg:gap-x-10 xl:gap-x-60">
            <div class="grid grid-rows-5 gap-1 pt-1">
              <div class="grid grid-cols-4 items-center">
                <div class="col-span-1">
                  <strong>Código Cliente/Entidad: </strong>
                </div>
                <div class="col-span-3">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs"
                             v-model="detailsRecepcion.encabezado.CardCode" disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="col-span-1">
                  <strong>Nombre Cliente/Entidad: </strong>
                </div>
                <div class="col-span-3">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs"
                             v-model="detailsRecepcion.encabezado.CardName" disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="col-span-1">
                  <strong>Usuario: </strong>
                </div>
                <div class="col-span-3">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="detailsRecepcion.encabezado.Usuario"
                             disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="col-span-1">
                  <strong>N. pedidos: </strong>
                </div>
                <div class="col-span-3">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs"
                             v-model="detailsRecepcion.encabezado.numero_pedidos" disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="col-span-1">
                  <strong>N. Factura: </strong>
                </div>
                <div class="col-span-3">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs"
                             v-model="detailsRecepcion.encabezado.NumAtCard" disabled/>
                </div>
              </div>
            </div>
            <div class="grid grid-rows-5 gap-1 pt-1">
              <div class="grid grid-cols-4 items-center">
                <div class="xl:col-end-4 col-span-1">
                  <strong>Fecha de Contabilización: </strong>
                </div>
                <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="detailsRecepcion.encabezado.TaxDate"
                             disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="xl:col-end-4 col-span-1">
                  <strong>Fecha de documento: </strong>
                </div>
                <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="detailsRecepcion.encabezado.DocDate"
                             disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="xl:col-end-4 col-span-1">
                  <strong>Fecha de vencimiento: </strong>
                </div>
                <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs"
                             v-model="detailsRecepcion.encabezado.DocDueDate" disabled/>
                </div>
              </div>
              <div class="grid grid-cols-4 items-center">
                <div class="xl:col-end-4 col-span-1">
                  <strong>Estado: </strong>
                </div>
                <div class="xl:col-end-5 col-span-3 xl:col-span-1">
                  <InputText class="p-inputtext-xxs inputsHeader text-xxs"
                             v-model="detailsRecepcion.encabezado.estadoNombre" disabled/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%" class="mt-6">
          <div>
            <DataTable
              :value="detailsRecepcion.detalle"
              class="p-datatable-sm text-xxs"
              showGridlines
              dataKey="LineNum"
              responsiveLayout="scroll"
              :scrollable="true"
              scrollHeight="500px"
            >
              <template #empty>
                No hay datos para mostrar
              </template>
              <Column field="LineNum" header="Indice" headerStyle="justify-content: center;text-align: center"
                      style="min-width:3rem;"
                      bodyStyle="justify-content: center;text-align: center">
                <template #body="{data}">
                  <div>{{ data.LineNum }}</div>
                </template>
              </Column>
              <Column field="ItemCode" header="Artículo" headerStyle="justify-content: center;"
                      style="min-width:25rem" bodyStyle="text-align: center">
                <template #body="{data}">
                  <div class="grid grid-cols-1 w-full">
                    <div class="flex justify-between items-center my-1">
                      <div>
                        <strong>Código Mx: </strong>{{ data.ItemCode }}
                      </div>
                      <div class="flex gap-2">
                        <Badge v-if="data.ReqMarca" :value="`Req. marca`"
                               class=" bg-red-300 text-red-700 text-xxs h-4 capitalize"/>
                        <Badge v-if="data.Controlado" value="Controlado"
                               class=" bg-green-300 text-green-700 text-xxs h-4 capitalize"></Badge>
                        <Badge v-if="data.Regulado" value="Regulado"
                               class=" bg-blue-300 text-red-blue text-xxs h-4 capitalize"></Badge>
                        <Badge v-if="data.temperatura > 0" value="Cadena Frio"
                               class="bg-blue-800 text-blue text-xxs h-4"></Badge>
                      </div>
                    </div>
                    <div class="flex">
                      <strong>Nombre: </strong>{{ data.ItemName }}
                    </div>
                  </div>
                </template>
              </Column>
              <Column field="Quantity" header="Cantidad total" headerStyle="justify-content: center;text-align: center"
                      style="min-width:5rem"
                      bodyStyle="justify-content: center;text-align: center">
                <template #body="{data}">
                  <div>{{ $h.formatNumber(data.Quantity) }}</div>
                </template>
              </Column>
              <Column field="" header="Precio" headerStyle="justify-content: center;text-align: center"
                      style="min-width:7rem"
                      bodyStyle="justify-content: center;text-align: center">
                <template #body="{data}">
                  <div>{{ $h.formatCurrency(data.UnitPrice) }}</div>
                </template>
              </Column>
              <Column field="" header="Total" headerStyle="justify-content: center;text-align: center"
                      style="min-width:7rem"
                      bodyStyle="justify-content: center;text-align: center">
                <template #body="{data}">
                  <div>{{ $h.formatCurrency(data.UnitPrice * data.Quantity) }}</div>
                </template>
              </Column>
              <Column field="lotes" header="Lotes" headerStyle="justify-content: center;text-align: center"
                      style="min-width:5rem;"
                      bodyStyle="justify-content: center;text-align: center"
                      v-if="validateShowColumn('lotes')"
              >
                <template #body="{data}">
                  <div v-if="!data.lotes.length">
                    <span class="text-red-600">Aún no posee lotes</span>
                  </div>
                  <div v-else>
                    <Button type="button" class="p-button-xs" icon="pi pi-th-large" label="Lotes"
                            @click="toggle(data)"/>
                  </div>
                </template>
              </Column>
              <Column field="DistNumber" header="Lote" headerStyle="justify-content: center;text-align: center"
                      style="min-width:8rem;"
                      bodyStyle="justify-content: center;text-align: center"
                      v-if="validateShowColumn('DistNumber')"/>
              <Column field="CantidadLote" header="Cant. lote" headerStyle="justify-content: center;text-align: center"
                      style="min-width:5rem;"
                      bodyStyle="justify-content: center;text-align: center"
                      v-if="validateShowColumn('CantidadLote')"/>
              <Column field="ExpDate" header="Fec. Vencimiento" headerStyle="justify-content: center;text-align: center"
                      style="min-width:5rem;"
                      bodyStyle="justify-content: center;text-align: center"
                      v-if="validateShowColumn('ExpDate')"/>
              <Column field="WarehouseCode" header="Almacén" headerStyle="justify-content: center;text-align: center"
                      style="min-width:15rem" bodyStyle="justify-content: center;text-align: center">
                <template #body="{data}">
                  <div>{{ data.WarehouseCode }} - {{ data.WhsName }}</div>
                </template>
              </Column>
              <Column field="" header="Distribución" headerStyle="justify-content: center;text-align: center"
                      style="min-width:6rem;" bodyStyle="justify-content: center;text-align: center"
                      v-if="validateShowColumn('distribucion')"
              >
                <template #body="{data}">
                  <div v-if="!data.distribucion.length">
                    <span class="text-red-600">Aún no posee distribución</span>
                  </div>
                  <div v-else>
                    <Button type="button" class="p-button-xs" icon="pi pi-th-large" label="Detalles"
                            @click="detalleDistribucion($event, data)" haspopup="true" aria-controls="op_distribucion"/>

                    <OverlayPanel ref="opDist" appendTo="body" :showCloseIcon="true" id="op_distribucion"
                                  style="width: 350px"
                                  :breakpoints="{'960px': '60vw'}" >
                      <DataTable :value="detalleDist" responsiveLayout="scroll" class="p-datatable-sm text-xxs">
                        <Column header="Bodega">
                          <template #body="slotProps">
                            <span>{{ slotProps.data.CodBodega }} - {{ slotProps.data.NombreBodega }}</span>
                          </template>
                        </Column>
                        <Column field="cantidad" header="Cantidad"/>
                      </DataTable>
                    </OverlayPanel>
                  </div>
                </template>
              </Column>
              <Column field="Ingreso" header="Pedido" headerStyle="justify-content: center;text-align: center"
                      style="min-width:5rem"
                      bodyStyle="justify-content: center;text-align: center">
                <template #body="{data}">
                  <div>{{ data.BaseRef }}</div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </template>
    </Card>
    <Card class="w-full mt-4">
      <template #content>
        <div class="w-full grid grid-cols-1 lg:grid-cols-3 gap-2 xl:gap-8 text-xxs">
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 p-1 gap-2 xl:gap-8 lg:col-span-2">
            <div class="flex flex-col">
              <p class="mb-2">
                <strong>Comentarios: </strong>
              </p>
              <Textarea
                class="p-inputtext-xxs"
                disabled
                :autoResize="true"
                v-model="detailsRecepcion.encabezado.Comments"
                rows="2"
                cols="40"
              />
            </div>
            <div class="flex flex-col">
              <p class="mb-2">
                <strong>Entrada de diario: </strong>
              </p>
              <Textarea
                class="p-inputtext-xxs"
                disabled
                :autoResize="true"
                v-model="detailsRecepcion.encabezado.JournalMemo"
                rows="2"
                cols="40"
              />
            </div>
            <div>
            </div>
          </div>
          <div class="lg:col-span-1">
            <div class="flex justify-between border-b-2 pb-3">
              <span class="text-gray-600">Total sin descuento:</span>
              <span class="">{{ $h.formatCurrency(total) }}</span>
            </div>
            <div class=" flex justify-between my-3">
              <div>
                <span class="text-gray-600 mr-4">% Descuento:</span>
                <InputNumber disabled inputId="integeronly" :min="0" :max="100"
                             inputClass="p-inputtext-xxs text-xxs w-10"
                             v-model="detailsRecepcion.encabezado.DiscountTotal"/>
              </div>
              <div>
                <span class="text-gray-600 mr-4">Total Descuento Documento:</span>
                <span class="">{{ $h.formatCurrency(descuentoDocumento) }}</span>
              </div>
            </div>
            <div class="flex justify-between my-3">
              <span class="text-gray-600">Total Descuento Lineas:</span>
              <span class="">{{ $h.formatCurrency(totalDescuento) }}</span>
            </div>
            <div class="flex justify-between my-3">
              <span class="text-gray-600">Total IVA:</span>
              <span class="">{{ $h.formatCurrency(totalSumIva) }}</span>
            </div>
            <div class="flex justify-between bg-blue-800 text-white rounded-lg p-2 text-sm">
              <span class="font-bold">Total:</span>
              <span class="">{{ $h.formatCurrency((total - totalDescuento - descuentoDocumento) + totalSumIva) }}</span>
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-2" v-if="previewEntrada">
          <Button label="Enviar a SAP"
                  icon="pi pi-upload"
                  class="p-button-rounded p-button-help text-xs xl:text-md" @click="sendToSAP"
          />
        </div>
      </template>
    </Card>
    <Sidebar
      v-model:visible="displaySideBar"
      position="bottom"
      :showCloseIcon="false"
      class="p-sidebar-sm"
      style="height: 15rem"
    >
      <div v-if="infoMedSeleccionado.ItemCode" class="flex justify-center text-base mb-2">
        <span>{{ infoMedSeleccionado.ItemCode }} - {{ infoMedSeleccionado.ItemName }}</span>
      </div>
      <div>
        <DataTable
          :value="infoLotes"
          class="p-datatable-sm text-xxs"
          showGridlines
          responsiveLayout="scroll"
          scrollHeight="500px"
          dataKey="id"
          rowGroupMode="rowspan"
          :groupRowsBy="infoLotes.some(a => a.novedades && a.novedades.length) ? ['DistNumber', 'CantidadLote', 'ExpDate','SL1Code'] : []"
        >
          <Column field="DistNumber" header="Lote" headerStyle="justify-content: center;"
                  style="width:10rem" bodyStyle="text-align: center"/>
          <Column field="CantidadLote" header="Cant. Lote" headerStyle="justify-content: center;"
                  style="width:5rem" bodyStyle="text-align: center">
            <template #body="{data}">
              <div>{{ $h.formatNumber(data.CantidadLote) }}</div>
            </template>
          </Column>
          <Column field="ExpDate" header="Fech. Vencimiento" headerStyle="justify-content: center;"
                  style="width:10rem" bodyStyle="text-align: center"/>
          <Column field="SL1Code" header="Ubicación" headerStyle="justify-content: center;"
                  style="width:10rem" bodyStyle="text-align: center"/>
          <Column field="nombreTipo" header="Tipo de novedad" headerStyle="justify-content: center;"
                  bodyStyle="text-align: center" style="min-width:5rem"/>
          <Column field="nombreDetalleNov" header="Detalle de novedad" headerStyle="justify-content: center;"
                  bodyStyle="text-align: center" style="min-width:10rem">
            <template #body="{data}">
<!--              <pre>{{data}}</pre>-->
              <div v-if="data.nombreDetalleNov">{{ data.detalleNov }} - {{ data.nombreDetalleNov }}</div>
            </template>
          </Column>
          <Column field="observacion" header="Observación" headerStyle="justify-content: center;"
                  bodyStyle="text-align: center; word-break: break-all;" style="min-width:5rem"/>
          <Column field="DistNumber" header="Acciones" headerStyle="justify-content: center;"
                  style="width:5rem" bodyStyle="text-align: center" v-if="infoLotes.some((a) => a.url)">
            <template #body="{data}">
              <Button v-tooltip.top="'Ver carta compromiso'" v-if="data.url" @click="viewCarta(data.id)"
                      class="p-button-text p-button-info p-0">
                <eyeIcon class="w-3/4"/>
              </Button>
            </template>
          </Column>
        </DataTable>
      </div>
    </Sidebar>
  </Dialog>
</template>

<script>
import { ref, computed } from 'vue'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'
import Swal from 'sweetalert2'

export default {
  name: 'modalRecepcion',
  emits: ['updateList'],
  setup (props, { emit }) {
    const displayModal = ref(false)
    const detailsRecepcion = ref([])
    const _RecepcionPedidosService = new RecepcionPedidosService()
    const displaySideBar = ref(false)
    const previewEntrada = ref(false)
    const opDist = ref()
    const detalleDist = ref([])
    const infoMedSeleccionado = ref({})
    const total = computed(() => {
      return [...new Set(detailsRecepcion.value.detalle.map(({ ItemCode }) => ItemCode))].reduce((suma, row) => {
        const { UnitPrice, Quantity } = detailsRecepcion.value.detalle.find((a) => a.ItemCode === row)
        return suma + (UnitPrice * Quantity)
      }, 0)
    })
    const descuentoDocumento = computed(() => {
      return ((total.value - totalDescuento.value) * (detailsRecepcion.value.encabezado.DiscountTotal / 100))
      // return 29097.4
    })
    const totalDescuento = computed(() => {
      return detailsRecepcion.value.detalle.reduce((total, current) => {
        return total + ((current.Quantity * current.UnitPrice) * (current.DiscountPercent / 100))
      }, 0)
    })
    const totalSumIva = computed(() => {
      return detailsRecepcion.value.detalle.reduce((total, current) => {
        return total + ((current.Quantity * current.UnitPrice) * current.taxRate / 100)
      }, 0)
    })
    const infoLotes = ref([])
    const openModal = (values, prevEntrada) => {
      detailsRecepcion.value = values
      previewEntrada.value = prevEntrada
      displayModal.value = true
    }
    const validateShowColumn = (column) => {
      let validColumns = ['lotes', 'distribucion']
      if (previewEntrada.value) {
        validColumns = ['DistNumber', 'CantidadLote', 'ExpDate']
      }
      return validColumns.includes(column)
    }

    const sendToSAP = () => {
      Swal.fire({
        icon: 'info',
        title: 'Esta seguro?',
        text: 'Se creará la entrada de mercancía en SAP luego no se podrá modificar. ¿Desea continuar?',
        focusCancel: true,
        showConfirmButton: true,
        showCancelButton: true
      }).then(async (resp) => {
        if (resp.isConfirmed) {
          await _RecepcionPedidosService.postSap(detailsRecepcion.value.encabezado.DocEntry).then(({ data }) => {
            if (data.affected) {
              Swal.fire({
                icon: 'success',
                title: 'Éxito',
                text: 'Se registro la información correctamente',
                showConfirmButton: true
              }).then((response) => {
                if (response.isConfirmed) {
                  displayModal.value = false
                  displaySideBar.value = false
                  emit('updateList')
                }
              })
            }
          }).catch(e => {
            console.log(e.error, e.message)
          })
        }
      })
    }
    const detalleDistribucion = (event, data) => {
      opDist.value.toggle(event)
      detalleDist.value = data.distribucion
    }
    const toggle = (data) => {
      infoLotes.value = []
      infoMedSeleccionado.value = {}
      for (const lot of data.lotes) {
        if (lot.novedades && lot.novedades.length) {
          for (const nov of lot.novedades) {
            infoLotes.value.push({
              ...lot,
              ...nov
            })
          }
          continue
        }
        infoLotes.value.push({
          ...lot,
          novId: null,
          idNovedad: null,
          codigoDetalleNov: null,
          nombreTipoNov: null,
          nombreDetalleNov: null,
          observacion: null,
          SL1Code: null
        })
      }
      infoMedSeleccionado.value = {
        ItemCode: data.ItemCode,
        ItemName: data.ItemName
      }
      displaySideBar.value = true
    }
    const viewCarta = (id) => {
      _RecepcionPedidosService.verPdf(id)
    }
    return {
      openModal,
      displayModal,
      detailsRecepcion,
      toggle,
      infoLotes,
      total,
      descuentoDocumento,
      totalDescuento,
      totalSumIva,
      displaySideBar,
      infoMedSeleccionado,
      previewEntrada,
      opDist,
      detalleDist,
      viewCarta,
      detalleDistribucion,
      validateShowColumn,
      sendToSAP
    }
  }
}
</script>

<style scoped>
.header {
  display: grid;
  box-shadow: 3px 3px 3px 4px whitesmoke;
}

.inputsHeader {
  background-color: #eef1f5;
  width: 100%;
  height: 100%;
}

.footerModal {
  display: flex;
  /*justify-content: space-between;*/
  padding: 10px;
}

::v-deep(.p-column-header-content) {
  justify-content: center;
}

::v-deep(.p-card-content) {
  padding: 0rem;
}

:global(.swal2-container) {
  z-index: 1000000 !important
}
</style>
